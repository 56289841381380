import styled from 'styled-components'

export const SidebarSticky = styled.div`
    margin:0;
`
export const SidebarForm = styled.div`
    border:2px solid #062c44;
    border-radius: 30px;
`
export const SidebarFormHeader = styled.div`
    background-color: #062c44;
    text-align:center;
    color:#fff;
    border-radius:28px 28px 0px 0px;
    padding:20px 15px;
    @media(min-width:576px){
        padding:20px;
    }
    @media(min-width:1200px){
        padding:20px 25px;
    }
    @media(min-width:1600px){
        padding:20px 30px;
    }
`
export const SidebarFormTitle = styled.div`
    font-weight:500;
    margin-bottom:0;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 992px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 1200px) {
        font-size: 24px;
        line-height: 34px;
    }
`
export const SidebarFormDescription = styled.div` 
    font-size: 14px;
    line-height:22px;
    /* margin:5px 0 0 0;
    @media (min-width: 576px) {
        margin:10px 0 0 0;
    } */
    @media (min-width: 992px) {
        font-size: 16px;
        line-height:28px;
        /* margin:15px 0 0 0; */
    }
    @media (min-width: 1600px) {
        font-size: 18px;
        line-height:30px;
    }
`
export const SidebarFormBody = styled.div`
    background-color:#fff;
    border-radius: 30px;
    padding:20px 15px;
    @media(min-width:576px){
        padding:20px;
    }
    @media(min-width:1200px){
        padding:20px 25px;
    }
    @media(min-width:1600px){
        padding:20px 30px;
    }
    .form{
        .form-coltwo{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .form-action{
            justify-content:center;
        }
        textarea.form-control {
            height:50px;
        }
    }
    &.single-form{
        .form{
            .form-coltwo{
                @media(min-width:576px){
                    flex: 0 0 50%;
                    max-width: 50%;
                    &:first-child{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
            textarea.form-control {
                height:50px;
                @media(min-width:1200px){
                    height:80px;
                }
                @media(min-width:1600px){
                    height:120px;
                }
            }
        }
    }
`

export const SidebarCard = styled.div`
    border:1px solid rgba(6, 44, 68, 0.5);
    margin-bottom:20px;
    @media(min-width:1200px){
        margin-bottom:30px;
    }
    @media(min-width:1600px){
        margin-bottom:40px;
    }
`
export const SidebarCardHeader = styled.div`
    color:#02044A;
    padding:15px 20px;
    position: relative;
    @media(min-width:1200px){
        padding:15px 25px;
    }
    @media(min-width:1600px){
        padding:15px 30px;
    }
`
export const SidebarCardTitle = styled.div`
    position: relative;
    font-weight:700;
    margin-bottom:0;
    font-size: 16px;
    line-height: 28px;
    border-bottom: 1px solid #062c44;
    padding-bottom:20px;
    &:after { 
        content:"";
        position:absolute;
        bottom: -2px;
        left:-1px;
        width:50px;
        height:4px;
        background-color:#062c44;
    }
    @media (min-width: 992px) {      
        font-size: 18px;
        line-height: 28px;
    }
    @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 30px;
    }
`
export const SidebarCardBody = styled.div`
    padding:15px 20px;
    @media(min-width:1200px){
        padding:15px 25px;
    }
    @media(min-width:1600px){
        padding:15px 30px;
    }
    
`
